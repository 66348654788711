import type { IProduct, IPromoProduct } from "@magnit/unit-catalog/src/unit-catalog.types";
import type { ISortingValue } from "@magnit/unit-catalog/src/types";
import type { ICatalogBFFDetailedGood } from "~/typings/api/goods";
import useProduct from "~/composables/useProduct";
import type { ICategory } from "~/typings/categories";
import type { ITicker } from "~/typings/api/content";

interface IProductsSliderPayload {
  item: IProduct;
  idx: number;
}

export const getActiveYaSlotInfo = (parent: Element | HTMLElement) => {
  const items = parent.querySelectorAll(".yaslot-item");
  const currentItem = Array.from(items).find((i) => {
    const style = getComputedStyle(i);
    return style.display === "block";
  });

  const htmlElement = currentItem?.id
    ? document.getElementById(currentItem?.id)
    : null;
  const currentYaSlotItemId = htmlElement?.dataset.yaSlotId || currentItem?.id;
  const currentItemHref = currentItem?.querySelector("a")?.href;
  const currentItemLink = currentItemHref
    ? new URL(currentItemHref).searchParams.get("pf")
    : "";

  return {
    promo_id: currentYaSlotItemId,
    dest: currentItemLink?.toString(),
  };
};

export const collectSearchListPayload = (
  query: string,
  products: IProduct[],
) => {
  return {
    query,
    list: products.map((item, idx) => {
      return {
        ...item,
        position: idx + 1,
      };
    }),
  };
};

export const collectCardDetailCommonProps = (
  product: ICatalogBFFDetailedGood | IPromoProduct | null,
) => {
  if (!product) return {};
  const { productCurrentCategory, productId, productName, productDiscountCategory } = useProduct(ref(product));

  return {
    categoryName: productCurrentCategory.value?.name,
    name: productName.value,
    product_id: productId.value,
    discountCategory: productDiscountCategory.value,
  };
};

const collectCardDetailPriceProps = (product: ICatalogBFFDetailedGood | IPromoProduct) => {
  const { productPrice, productOldPrice } = useProduct(ref(product));

  return {
    price: productPrice.value,
    old_price: productOldPrice.value,
  };
};

export const collectGoodsProductViewPayload = (product?: ICatalogBFFDetailedGood | null) => {
  if (!product) return {};

  return {
    ...collectCardDetailCommonProps(product),
    ...collectCardDetailPriceProps(product),
    discountPercentage: product.promotion.discountPercent,
  };
};

export const collectPromoProductViewPayload = (product?: IPromoProduct | null) => {
  if (!product) return {};
  return {
    ...collectCardDetailCommonProps(product),
    ...collectCardDetailPriceProps(product),
    startDate: product.startDate,
    endDate: product.endDate,
    progressiveDiscount: product.progressiveDiscount,
    progressiveDiscountType: product.progressiveDiscountType,
    discountType: product.discountType,
    discountValue: product.discountValue,
    previewDiscountTitle: product.previewDiscountTitle,
  };
};

export const collectCatalogListingPayload = (
  sort: ISortingValue | null,
  innerFilters: any,
  category: ICategory | null,
) => {
  return {
    sort_type: sort?.value || null,
    filter_type: innerFilters,
    category_name: category?.name,
  };
};

export const collectProductsSliderPayload = (payload: IProductsSliderPayload) => {
  const { idx, item } = payload;
  return {
    item_id: item.id,
    item_name: item.title,
    dest: item.link,
    position: idx,
  };
};

export const collectTickerPayload = (item: ITicker) => ({
  promo_id: item.id,
  dest: item.offerURL,
  promo_name: item.title || "",
});
